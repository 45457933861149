import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { Alert, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { fetchLatestData, postNewData } from '../../redux/action/customFile';

export default function AgentList() {
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const getResults = () => {
    fetchLatestData(
      "agents/get/all",
      {},
      (data) => {
        console.log("=====================", data);
        setResults(data); // Ensure data structure matches
        setFilteredResults(data); // Initialize filteredResults with all results
      },
      (err) => {
        console.error(err);
      }
    );
  };

  useEffect(() => {
    getResults();
  }, []);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = results && results.filter(item =>
      // item.name?.toLowerCase().includes(lowercasedQuery) ||
      item.agentName.toLowerCase().includes(lowercasedQuery) || // Ensure agentName is considered
      item.phoneNumber.includes(lowercasedQuery)
      // item.status?.toString().toLowerCase().includes(lowercasedQuery) // If status is a boolean, convert to string
    );
    setFilteredResults(filtered);
  }, [searchQuery, results]);

  const handleDelete = (id) => {
    postNewData(
      { id: id },
      "delete/agent/id",
      (data) => {
        alert(data.message);
        getResults();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">AGENT RECORD</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Dashboard</a></li>
            <li className="breadcrumb-item active" aria-current="page">agent</li>
          </ol>
        </nav>
      </div>

      <Card>
        <CardHeader>AGENT LIST</CardHeader>
        <CardBody>
          <div className='row mb-3'>
            <Form.Label htmlFor="searchInput">Search bar</Form.Label>
            <Form.Control
              id="searchInput"
              type="search"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              aria-describedby="passwordHelpBlock"
              placeholder='Search by: agent name, phone number'
            />
          </div>

          <div className="table-responsive">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>PHONE NUMBER</th>
                  <th>TOTAL AGENT PASSENGERS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {filteredResults && filteredResults.map((item, i) => (
                  <tr key={i}>
                    <td>{i +1}</td>
                    <td>{item.agentName}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.voucherCount || 0}</td>
                    <td>
                      <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {!filteredResults.length &&
            <Alert>No data availale at this time</Alert>
            }
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
