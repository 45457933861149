import React from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactPDF, { Document, Page, Text, View, Image, Font, StyleSheet } from '@react-pdf/renderer';
// import QRCodeGenerator from './QRCodeGenerator';
import QRCode from 'qrcode';

// import QRCode from 'qrcode';
// Register the font
// Font.register({
//   family: 'Helvetica',
//   src: 'https://fonts.gstatic.com/s/helvetica/v16/fC1mPYtObGbfyQznIaP9vYGEiMjY1o8.ttf'
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: 'Times-Italic',
    fontSize: 12,
  },
  heading: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10,
  },
  heading2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 10,
    color: 'blue'
  },
  qrCode: {
    width: 80,
    height: 80,
    marginLeft: 'auto',
  },
  text: {
    fontSize: 14,
    marginBottom: 8,
    marginTop: 8
  },
  userId: {
    fontSize: 14,
    marginBottom: 8,
    // marginTop: 8,
    fontWeight: 'bold',
  },
  textBold: {
    fontWeight: 'bold',
  },
  space: {
    marginBottom: 20,
  },
  tableContainer: {
    flexDirection: 'row',
    marginTop: 0,
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: 2,
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'blue',
  },
  tableData: {
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: 5,
    flex: 1,
    textAlign: 'center',
  },
  paragraph: {
    fontSize: 10,
    marginTop: 5,
    marginBottom: 0,

  },
  flexer: {
    display: 'flex',
    flexDirection: 'row',
  },
  secTitle: {
    fontWeight: 'bold',
  },
  repsStyle: {
    fontWeight: 'bold',
    color: 'blue'
  }
});

const generateQRCodeImage = async (data) => {
  try {
    const canvas = document.createElement('canvas');
    await QRCode.toCanvas(canvas, data, { width: 200 });
    return canvas.toDataURL();
  } catch (error) {
    console.error('Error generating QR code:', error);
    return null; // Return null in case of an error
  }
};

const PdfFile = ({ data, userId }) => {
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState('');
  const userLocal = localStorage.getItem('userId');

  // console.log("data.user.userId || userLocal", data && data.user && data.user.userId || userLocal)

  useEffect(() => {
    const generateQRCode = async () => {
      const mergedData = `https://voucher.hamsyltravels.com/search/print/${data.search_id}`
      // const mergedData =  `https://hamsyltravels.com/${data.voucherId}`
      // const mergedData =  `https://linktr.ee/hamsyl_travel_and_tour?utm_source=linktree_admin_share`

      try {
        const qrCodeDataURL = await generateQRCodeImage(mergedData);
        setQrCodeImageUrl(qrCodeDataURL || ''); // Ensure the URL is always a non-null string
      } catch (error) {
        console.error('Error generating QR code:', error);
        setQrCodeImageUrl(''); // Handle error by setting an empty string as URL
      }
    };

    generateQRCode();
  }, [data]);

  let makkahRepresentative;
  if (data.hotelMakkah === "LAND PREMIUM FLOOR 1") {
    makkahRepresentative = (
      <Text style={styles.textBold}>
        <Text style={styles.repsStyle}>MAKKAH REPRESENTATIVE: </Text>
        ABDULKADIR (+966) 0542796376 
      </Text>
    );
  } else if (data.hotelMakkah === "LAND PREMIUM FLOOR 2") {
    makkahRepresentative = (
      <Text style={styles.textBold}>
        <Text style={styles.repsStyle}>MAKKAH REPRESENTATIVE: </Text>
        NASIRU (+966) 560596969 
      </Text>
    );
  } else {
    makkahRepresentative = (
      <Text style={styles.textBold}>
        <Text style={styles.repsStyle}>MAKKAH REPRESENTATIVE: </Text>
        ABDULAZIZ (+966) 594790653, SUNUSI (+966) 564016608
      </Text>
    );
  }

  // console.log("=================", data.multipleReg)
  // Check if qrCodeImageUrl is available, if not, show a placeholder

  const qrCodeImage = qrCodeImageUrl ? (
    <Image src={qrCodeImageUrl} />
  ) : (
    <Text style={{ fontSize: 12, color: 'red' }}>Error generating QR code</Text>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.heading}>HAMSYL TRAVEL AND TOURS LTD</Text>
        <Text style={styles.heading2}>RAMADAN SEASON 2024/2025</Text>
        <Text style={styles.textBold}>
          <Text style={styles.repsStyle}>
            JEDDAH AIRPORT REPRESENTATIVE:
          </Text>
          {" "} BAITA (+966) 564293357 </Text>
        <Text style={styles.space} />
        <Text style={styles.textBold}>
          <Text style={styles.repsStyle}>
            MADINA REPRESENTATIVE: {" "}
          </Text>
          IBRAHIM TELA  (+966) 500253118,  SABIB'U (+966) 545636743, SALISU (+966) 593616251  </Text>
        {/* <Text style={styles.space} /> */}
        {/* <Text style={styles.textBold}></Text> */}
        <Text style={styles.space} />
        {makkahRepresentative}
        <Text style={styles.space} />

        <View style={styles.flexer}>
          <View>
            <Text style={styles.text}>
              <Text style={styles.secTitle}>AGENT: </Text>
              {data.agentName}</Text>
            {/* <Text style={styles.textBold}>PASSENGER DETAILS</Text> */}
            <Text style={styles.text}>
              <Text style={styles.secTitle}>AIRLINES:</Text>
              {data.airline}</Text>
            <Text style={styles.userId}>{data && data.user && data.user.userId || userLocal}</Text>

          </View>
          {/* Adding QR Code image */}
          {/* <Image src={qrCodeImageUrl} /> */}
          <View style={styles.qrCode}>
            {qrCodeImage}
            {/* <Image src={qrCodeRef.current?.toDataURL()} /> */}
          </View>
        </View>

        <View style={styles.tableContainer}>
          <Text style={styles.tableHeader}>S/N</Text>
          <Text style={styles.tableHeader}>REG ID</Text>
          <Text style={styles.tableHeader}>Name</Text>
          {/* <Text style={styles.tableHeader}>Name</Text> */}
          <Text style={styles.tableHeader}>Passport</Text>
        </View>

        <View style={styles.tableContainer}>
          <Text style={styles.tableData}>{data && data.multipleReg ? data.multipleReg.length || 0 : 0}</Text>
          <Text style={styles.tableData}>{data && data.voucherId}</Text>
          {/* <View style={styles.tableData}>
            {data && data.multipleReg.map((item, index) => (
              <View key={index}>
                <Text>{item.name}</Text>
              </View>
            ))}
          </View> */}
          <View style={styles.tableData}>
            {data && data.multipleReg && Array.isArray(data.multipleReg) ? (
              data.multipleReg.map((item, index) => (
                <View key={index}>
                  <Text>{item.name}</Text>
                </View>
              ))
            ) : (
              <Text>No data available</Text>
            )}
          </View>

          {/* <View style={styles.tableData}>
            {data && data.multipleReg.map((item, index) => (
              <View key={index}>
                <Text>{item.passport}</Text>
              </View>
            ))}
          </View> */}

          <View style={styles.tableData}>
            {data && data.multipleReg && Array.isArray(data.multipleReg) ? (
              data.multipleReg.map((item, index) => (
                <View key={index}>
                  <Text>{item.passport}</Text>
                </View>
              ))
            ) : (
              <Text>No data available</Text>
            )}
          </View>
        </View>

        <Text style={{ color: 'white' }}>Travel Details</Text>
        <View style={styles.tableContainer}>
          <Text style={styles.tableHeader}>City</Text>
          <Text style={styles.tableHeader}>No of Nights</Text>
          <Text style={styles.tableHeader}>Room Type</Text>
          <Text style={styles.tableHeader}>Hotel</Text>
          <Text style={styles.tableHeader}>Check-in / Check-out</Text>
        </View>

        <View style={styles.tableContainer}>
          <Text style={styles.tableData}>{data && data.cityMadina}</Text>
          <Text style={styles.tableData}>{data && data.nightsMadina}</Text>
          <Text style={styles.tableData}>{data && data.roomTypeMadina}</Text>
          <Text style={styles.tableData}>{data && data.hotelMadina}</Text>
          <Text style={styles.tableData}>{data && data.checkInMadina} {data.checkOutMadina}</Text>-
        </View>

        <Text style={{ color: 'white' }}>Travel Details</Text>
        <View style={styles.tableContainer}>
          <Text style={styles.tableHeader}>City</Text>
          <Text style={styles.tableHeader}>No of Nights</Text>
          <Text style={styles.tableHeader}>Room Type</Text>
          <Text style={styles.tableHeader}>Hotel</Text>
          <Text style={styles.tableHeader}>Check-in / Check-out</Text>
        </View>

        <View style={styles.tableContainer}>
          <Text style={styles.tableData}>{data && data.cityMakkah}</Text>
          <Text style={styles.tableData}>{data && data.nightsMakkah}</Text>
          <Text style={styles.tableData}>{data && data.roomTypeMakkah}</Text>
          <Text style={styles.tableData}>{data && data.hotelMakkah}</Text>
          <Text style={styles.tableData}>{data && data.checkInMakkah} {data.checkOutMakkah}</Text>-
        </View>

        <Text style={{ color: 'white' }}>Travel Details</Text>
        <View style={styles.tableContainer}>
          <Text style={styles.tableHeader}>TRANSPORTATION</Text>
          <Text style={styles.tableHeader}>{data && data.transportationType}</Text>
          <Text style={styles.tableHeader}>{data && data.transportationType}</Text>
        </View>

        <Text style={styles.paragraph}></Text>
        <Text style={styles.paragraph}>
          PLS DOWNLOAD NUSUK APP ON YOUR APPLICATION STORE TO ISSUE THE NECESSARY PERMITS TO VISIT RAWDAH AND PROPHET MOSQUE.
        </Text>

        <Text style={{ color: 'white' }}>Travel Details</Text>

        <Text style={styles.textBold}>THIS PACKAGE IS VALID ONLY FOR UMRAH SEASON 2024/2025 </Text>

        <Text style={styles.paragraph}>
          TRANSPORTATION IS ONLY PROVIDED FOR GROUPS IF YOU ARRIVED NOT IN A GROUP YOU WILL BE RESPONSIBLE FOR YOUR OWN TRANSPORTATION
        </Text>

        <Text style={styles.paragraph}>
          PACKAGE IS NOT REFUNDABLE UNDER ANY CIRCUMSTANCES AND ONLY MAXIMUM 4 DAYS IS ALLOWED IN MADINA AND CHECK OUT TIME IS 12:00PM
        </Text>

        <Text style={styles.paragraph}>
          CHECK IN TIME IS 400PM ANY ARRIVAL BEFORE THIS TIME IS SUBJECT TO WAITING OR AVAILABILITY DURING HIGH SEASON
        </Text>

        <Text style={styles.paragraph}>
          THE HOTELS ABOVE ARE SUBJECT TO AVAILABILITY, WE HAVE THE RIGHT TO EXCHANGE HOTELS MENTION ABOVE WITH OUT PRIOR NOTICE
        </Text>

        <Text style={styles.paragraph}>
          I ADMIT THAT THE ABOVE PACKAGE IS CHOSEN BY ME
        </Text>
      </Page>
    </Document>
  )
}

export default PdfFile;
